<template>
  <div>
    <div class="vs-row hero">
      <div class="hero-text self-center mx-20">
        <p class="text-5xl font-bold pl-4">Favourite</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>
    <div class="mx-10">
      <div class="mt-10">
        <div class="vs-row sm:pb-16 pb-24">
          <div class="vs-col sm:w-1/2 w-full">
            <p class="text-2xl font-bold">Favourite</p>
          </div>
          <div class="vs-col sm:w-1/2 w-full flex justify-end">
            <vs-button class="btn ml-3" color="danger" text-color="#fff" icon="delete">Remove All</vs-button>
          </div>
        </div>
      </div>
      <div class="vs-row px-4 mb-base">
        <div class="vs-col xl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full pb-4 px-4" v-for="(item, index) in favourites" :key="item.index" :offset="index > 0 ? 2 : 0">
          <el-card class="card" :body-style="{ padding: '0px' }">
            <div class="cardBody">
              <span class="material-icons btn">favorite</span>
              <img :src="item.banner" class="image">
            </div>
            <div class="footer" style="padding: 14px;">
              <span>{{item.title}}</span>
              <div class="mt-1">
                <i class="el-icon-location blue"></i><span class="time mx-1"> {{item.location[0].branch}} | {{item.age_from}}-{{item.age_to}} Years | {{item.interests_name}}</span>                
                <div class="mx-1 bottom">
                  <span class="blue">Price: </span><span class="orange">{{item.location[0].price}} KWD</span>
                </div>
                <a class="blue cursor-pointer">
                  <span class="link" @click="openActivityView(item)">View Details</span>
                  <span class="material-icons float-right mr-1" style="margin-top:-4px;">open_in_new</span>
                </a>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios.js'

export default {
  data () {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'Profile',
          url: 'profile',
        },
        {
          title: 'Favourite',
          active: true
        }
      ],
      favourites: []
    }
  },
  methods: {
    loadFavourites () {
      this.$vs.loading()
      axios.post('v1/user/getFavoriteActivities', {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.favourites = response.data.payload
          this.$vs.loading.close()
        }
      })
    },
    openActivityView (item) {
      this.$router.push({
        name: 'activity-details',
        params: {
          activityID: item.id
        }
      })
    }
  },
  created () {
    this.loadFavourites()
  }
}
</script>

<style scoped>
.hero {
  background-image: url('../assets/images/footer.jpg');
}
.btn {
  padding: 7px 20px;
  border-radius: 5px;
}
.el-card {
  height: 377px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.topPicks {
  color: #00DCDC;
  border-bottom: 1px solid #00DCDC;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  font-size: 13px;
  margin-bottom: -0px;
}
.link {
  padding-bottom: 10px;
  float: right;
  font-size: 13px;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.blue {
  color: #00DCDC;
}
.orange {
  color: #F89416;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color:#00DCDC;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.cardBody .topPicksText {
  color:#000;
  padding: 5px;
  width: 250px;
  background-color: #fff;
  position: absolute;
  bottom: -20px;
  left: 125px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 1.25rem;
  line-height: 1.75rem;
  border: none;
  text-align: center;
}
.cardBody .btn:hover {
  background-color: #999;
}
</style>